import "./Header.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function Header() {
  return (
    <>
      <header className="header container" id="inicio">
        <div className="header-inner">
          <div className="container-fluid px-lg-5">
            <nav className="navbar navbar-expand-lg my-navbar">
              <a className="navbar-brand " className="logo" href="/">
                <img alt=" " className="img-fluid" src="./herbertlogo.png" />
              </a>
              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <FontAwesomeIcon className="icon" icon={["fas", "bars"]} />
              </button>

              <div
                className="collapse navbar-collapse"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav ml-auto">
                  <li className="nav-item ">
                    <a className="nav-link" href="#inicio">
                      Início <span className="sr-only">(current)</span>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#sobre">
                      Sobre
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#atuacao">
                      Atuação
                    </a>
                  </li>
                  {/* <li className="nav-item">
                    <a className="nav-link" href="#blog">
                      Blog
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#depoimentos">
                      Depoimentos
                    </a>
                  </li> */}
                  <li className="nav-item">
                    <a className="nav-link" href="#contato">
                      Contato
                    </a>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </div>
      </header>

      <section className="content-banner parallax">
        <div className="container">
          <div className="row d-flex justify-content-center">
            <div className="col-md-12">
              <div className="banner-con text-center">
                <p className="first-title"> Herbert Krutsch Advocacia</p>
                <p className="banner-des">
                  Especialista em Direito Civil e Constitucional
                </p>
                <a
                  className="btn btn-b "
                  href="https://api.whatsapp.com/send?phone=556992593012&text=Olá Herbert, Poderia me ajudar?"
                  rel="noreferrer"
                  target="_blank"
                  role="button"
                >
                  Entrar em contato
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Header;
