import "./Atuação.css";
import { Row } from "react-bootstrap";
import { Col } from "react-bootstrap";

function Atuação() {
  return (
    <section>
      <div className="atuacao" align="center" id="atuacao">
        <div className="container">
          <Row>
            <Col>
              <div className="col-md-12" align="left">
                <h1  data-aos="fade-right">ÁREA DE ATUAÇÃO</h1>

                <h2  data-aos="fade-right">Cível</h2>
                <br></br>
                <p className="c atuacao-text"  data-aos="fade-right">
                  - Orientação na estruturação de negócios e contratos em geral;
                  <br></br>- Questões relativas à compra, venda e locação de
                  imóveis, desapropriação, hipoteca e alienação fiduciária;
                  <br></br>- Relações e contratos de consumo em geral;
                  <br></br>- Indenizações decorrentes de danos em geral
                  (material, moral e estético);
                  <br></br>- Análise e revisão judicial de contratos bancários.
                  <br></br>- Processos judiciais de inventário e inventário
                  extrajudicial;
                  <br></br>- Estabelecimento de salvaguardas em relação ao
                  direito de família (acordos de união estável e pactos
                  antenupciais);
                  <br></br>- Consultoria e mediação em casos de família (adoção,
                  pensão alimentícia, regulamentação de guarda e visita,
                  separação, divórcio e união consensual;
                  <br></br>- Planejamento sucessório com o objetivo de evitar
                  deterioração prematura dos ativos em decorrência de conflitos
                  familiares;
                  <br></br>- Orientação em instituição de testamento.
                </p>
                <br></br>
                <h2>Constitucional</h2>
                <br></br>
                <p className="c atuacao-text">
                  - Writs Constitucionais: Mandado de Segurança (individual e
                  coletivo), mandado de injunção, habeas datas;
                  <br></br>- Reclamação Constitucional;
                  <br></br>- Consultoria jurídica. Elaboração de pareceres
                  técnico-jurídicos;
                  <br></br>- Elaboração de pareceres acerca da
                  constitucionalidade de leis ou atos normativos pelos clientes
                  solicitados;
                  <br></br>- Representação em ações judiciais cujos argumentos
                  tratem de Direito Constitucional.
                </p>
              </div>

              <div className="container ">
                <div className="row d-flex justify-content-center cards">
                  <div
                    className="col-md-5 atuacao-card "
                    align="left"
                    data-aos="fade-right"
                  >
                    <div className="d-flex align-items-center topo">
                      <div className="p-2 line-title">
                        <h2>CÍVEL</h2>
                      </div>
                      <div className="ml-auto img-icon p-2">
                        <img alt=" " src="./img/balance.png"></img>
                      </div>
                    </div>
                    <div className="text-card">
                      <p className="l">
                        Em resumo, lido com as relações jurídicas, dentre elas
                        os direitos e as obrigações, das pessoas físicas e
                        jurídicas dentro da esfera cível, que tem como objetivo
                        implicar quais serão as regras e condutas que as pessoas
                        devem ter em sociedade.
                      </p>
                    </div>
                  </div>

                  <div
                    className="col-md-5 atuacao-card "
                    align="left"
                    data-aos="fade-right"
                  >
                    <div className="d-flex align-items-center topo">
                      <div className="p-2 line-title">
                        <h2>CONSTITUCIONAL</h2>
                      </div>
                      <div className="ml-auto img-icon p-2">
                        <img alt=" " src="./img/balance.png"></img>
                      </div>
                    </div>
                    <div className="text-card">
                      <p className="l">
                        Posso lidar com ações que abrange a espera
                        constitucional, para a salvaguarda de direitos
                        fundamentais e sociais por meio de ações tais quais
                        mandado de segurança, <i>habeas data</i>,
                        e Representação em ações judiciais.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </section>
  );
}
export default Atuação;
