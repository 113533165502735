import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import Header from './components/Header';
import Sobre from './components/Sobre';
// import Sessao from './components/Sessao';
import Atuacao from './components/Atuacao';
// import Blog from './components/Blog';
// import Depoimentos from './components/Depoimentos';
import Contato from './components/Contato';
import Instagram from './components/Instagram';
import Footer from './components/Footer';
import 'bootstrap/dist/css/bootstrap.min.css';
import './fontawesome';
import { Analytics } from '@vercel/analytics/react';

ReactDOM.render(
  <>
    <App/>
    <Header/>
    {/* <Sessao/> */}
    <Sobre/>
    <Atuacao/>
    <Analytics />
    {/* <Blog/> */}
    {/* <Depoimentos/> */}
    <Contato/>
    <Instagram/>
    <Footer/>
  </>,
  document.getElementById('root')
);

