import "./Contato.css";
import emailjs from "emailjs-com";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function Contato() {
  function sendEmail(e) {
    e.preventDefault();

    emailjs
      .sendForm(
        "outlookMessage",
        "template_c2lbamv",
        e.target,
        "user_xEcyYFbpGcZEqMyoxboVi"
      )
      .then(
        (result) => {
          alert("Mensagem enviada com sucesso!");
        },
        (error) => {
          alert(error.message);
        }
      );
    e.target.reset();
  }
  
  return (
    <section className="contato" id="contato">
      <div className="container" align="left">
        <h1  data-aos="fade-right">CONTATO</h1>
        <p className="c"  data-aos="fade-right">
          Entre em contato para tirar dúvidas e assim que receber sua mensagem,
          entrarei em contato com você em breve, estou aqui para lhe ajudar.
        </p>
      </div>
      <form className="container col-md-5 form" onSubmit={sendEmail}>
        <div className="row">
          <div className="col-md-7">
            <div class="form-outline mb-4">
              <input
                required
                type="text"
                id="form4Example1"
                placeholder="Nome"
                name="name"
                class="form-control"
              />
              <span className="error"></span>
            </div>
            <div class="">
              <div class="">
                <div class="form-outline mb-4">
                  <input
                    required
                    type="email"
                    id="form4Example2"
                    placeholder="E-mail"
                    name="email"
                    class="form-control"
                  />
                  <span className="error"></span>
                </div>
              </div>
              <div class="">
                <div class="form-outline mb-4">
                  <input
                    required
                    type="number"
                    name="number"
                    id="number"
                    class="form-control"
                    placeholder="(xx) xxxxx-xxxx"
                  />
                  <span className="error"></span>
                </div>
              </div>
            </div>

            <div class="form-outline mb-4">
              <textarea
                type="textarea"
                required
                class="form-control"
                id="form4Example3"
                name="message"
                placeholder="Conte-nos um pouco sobre o que deseja..."
                rows="4"
              ></textarea>
              <span className="error"></span>
            </div>
            <button type="submit" class="btn btn-primary mb-4">
              Enviar
            </button>
          </div>
          <div className="col-md-5 texto">
            <div>
              <ul className="list-unstyled texto-contato">
                <li>
                  <h3>TELEFONE</h3>
                  <p className="c">+55 69 9259-3012</p>
                </li>
                <li>
                  <h3>EMAIL</h3>
                  <p className="c">herbertkrutsch.adv@outlook.com</p>
                </li>
                <li>
                  <div className="icon-contato">
                    <a
                      href="https://www.instagram.com/herbertkrutsch.adv/"
                      rel="noreferrer"
                      target="_blank"
                    >
                      <FontAwesomeIcon
                        className="icon-contato"
                        icon={["fab", "instagram"]}
                      />
                    </a>
                    <a
                      href="https://www.linkedin.com/in/herbert-krutsch-6a4609215/"
                      rel="noreferrer"
                      target="_blank"
                    >
                      <FontAwesomeIcon
                        className="icon-contato"
                        icon={["fab", "linkedin-in"]}
                      />
                    </a>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </form>
    </section>
  );
}
export default Contato;
