import "./Sobre.css";

function Sobre() {
  return (
    <section>
      <div className="Sobre" align="center" id="sobre">
        <div className="container">
          <div className="row sobre">
            <div className="col"  data-aos="fade-right">
              <div className="col-md-12" align="left">
                <h1>SOBRE MIM</h1>
                <p className="c text-sobre">
                  Sou Herbert dos Anjos Krutsch, moro em Ji-Paraná/RO, graduado
                  em Direito pelo antigo Centro Universitário Luterano de
                  Ji-Paraná, CEULJI/ULBRA, pós-graduado em Direito Civil e
                  Empresarial e em Direito Constitucional pela Damásio
                  Educacional. Possuo experiência em trâmites processuais, mais
                  especificadamente em processos do juizado especial cível.
                </p>

                <div className="formacao">
                  <h2>Graduação</h2>
                  <p className="c">
                    Centro Universitário Luterano de Ji-Paraná.
                  </p>
                </div>
                <div className="pos-graduacao">
                  <h2>Pós-graduação <i>latu sensu</i></h2>
                  <p className="c">Direito Civil e Empresarial</p>
                  <p className="c">Direito Constitucional</p>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div>
                <img alt=" " className="card-img-top" src="./herbert.png" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default Sobre;
