
function Instagram() {


  return (
    <section id="instagram">
    <div class="elfsight-app-09ff20a3-3702-4940-bcb7-2fda0401608a"></div>
    </section>
  );
}
export default Instagram;
