import "./Footer.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function Footer() {
  return (
    <section>
      <div className="Footer" id="footer">
        <footer className="text-left ">
          <div className="container p-4">
            <div className="row d-flex justify-content-center footer-link">
              <div className="col-lg-6 col-md-12 mb-4 mb-md-0">
                <img src="./herbertlogo.png" alt=" " />
                <p className="text-footer">
                  Além de buscar a boa aplicação do direito, prezo o respeito,
                  clareza e honestidade ao cliente, com o objetivo de, em
                  primeiro lugar, lhe garantir o que é justo.
                </p>
              </div>
              <div className="col-lg-4">
                <ul className="list-unstyled mb-0">
                  <li>
                    <h3>Telefone</h3>
                    <p>+55 69 9259-3012</p>
                  </li>
                  <li>
                    <h3>Email</h3>
                    <p>herbertkrutsch.adv@outlook.com</p>
                  </li>
                  <li>
                    <div>
                      <a
                        href="https://www.instagram.com/herbertkrutsch.adv/"
                        rel="noreferrer"
                        target="_blank"
                      >
                        <FontAwesomeIcon
                          className="icon"
                          icon={["fab", "instagram"]}
                        />
                      </a>
                      <a
                        href="https://www.linkedin.com/in/herbert-krutsch-6a4609215/"
                        rel="noreferrer"
                        target="_blank"
                      >
                        <FontAwesomeIcon
                          className="icon"
                          icon={["fab", "linkedin-in"]}
                        />
                      </a>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="text-center exemplo p-3">
            <p className="c">
              © 2021 Copyright:{" "}
              <a
                href="https://www.behance.net/jacksoncarelli"
                rel="noreferrer"
                target="_blank"
              >
                Jackson Carelli
              </a>
              -&nbsp;Todos os direitos reservados
            </p>
          </div>
        </footer>
      </div>
    </section>
  );
}
export default Footer;
