import {library} from '@fortawesome/fontawesome-svg-core'
import {
  faBars,
  faBalanceScale,
} from '@fortawesome/free-solid-svg-icons'

import {
  faFacebookF,
  faTwitter,
  faLinkedinIn,
  faSkype,
  faInstagram,
} from '@fortawesome/free-brands-svg-icons'

library.add(
  faFacebookF,
  faTwitter,
  faLinkedinIn,
  faSkype,
  faInstagram,
  faBars,
  faBalanceScale,
)
